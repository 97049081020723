// eslint-disable-next-line
export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        development: "http://127.0.0.1:8001",
        // production: "http://127.0.0.1:8001", 
        production: "https://belakangsolo.apjii.or.id",         
        // development: "http://192.168.18.108:8001",
        // production: "http://192.168.18.108:8001", 
    },
    wsBaseUrl :{
        development : "ws://127.0.0.1:8001/ws/",
        // production : "ws://127.0.0.1:8001/ws/",
        production : "wss://belakangsolo.apjii.or.id/ws/",
    },
    localStorageKey : {      
        USER: "__apjiiClientUser",
        ACCESS_TOKEN: "__apjiiClientToken",      
        PERUSAHAAN : '_apjiiClientperusahaanInfo',
    },
    cookiesName : 'apjii_client',  
    secondCookiesName : 'apjii_client',  
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 10000,
    endPoint : {
        login : `/auth/api/newlogin`,
        testLogin : `/auth/api/v1/test-login/`,
        getRole : '/auth/api/get-all-role',

        vote : `/vote/api/v1/vote-voter/`,
        voteAdmin : `/vote/api/v1/vote-admin/`,
        votePause : `/vote/api/v1/vote-pause/`,
        voteResult : `/vote/api/v1/vote-result/`,
        voteAnswer : `/vote/api/v1/vote-answer/`,
        assignVoteToSesi : `/vote/api/v1/assign-vote-tosesi/`,
        postVote : `/vote/api/v1/post-vote/`,
        voteDashboard : `/vote/api/v1/vote-dashboard/`,
        daftarHadirDashboard : `/munas/api/v1/daftar-hadir-dashboard/`,
        voteClose : `/vote/api/v1/close-vote/`,
        votePauseStart : `/vote/api/v1/pause-start-vote/`,
        voterLogin : `/munas/api/v1/voter-login/`,
        
        munasPeserta : `/munas/api/v1/munaspeserta/`,
        postRegistrasiPeserta : `/munas/api/v1/munas-peserta-checked-registrasi/`,
    }
}