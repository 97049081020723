import { Fragment } from "react"


const NotFoundPage = (props) => {
    return(
        <Fragment>
            <div className='bg-slate-50/60 py-[14px] px-[25px] rounded'>
                <div className="flex flex-col justify-between items-center mb-40 ">
                    <div className='mt-40 font-bold text-[180px] text-blue-500' style={{lineHeight: 1}}>404</div>
                    <div className='font-bold text-[40px] text-slate-800' style={{lineHeight: 1}}>Page Not Found</div>
                    { sessionStorage.getItem('error_message') &&
                        <div className='text-slate-800 text-[25px] mt-2 font-semibold'>{ sessionStorage.getItem('error_message') }</div>
                    }
                </div>
            </div>            
        </Fragment>
    )
}
export default NotFoundPage;