import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

const DefaultLayout = (props) => {
    return(
        <Fragment>
            <div className="bg-image-utama bg-cover bg-center h-screen overflow-hidden overflow-y-auto">
                {/* <div className='header bg-slate-50/40 w-full h-[80px]'>

                </div> */}
                
                <Outlet/>                
			</div>
        </Fragment>
    )
}
export default DefaultLayout;