import React, { Fragment, useEffect, useCallback } from 'react';
import {  useParams } from 'react-router';
import axios from 'axios'

import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import config from '../../constants/config';

const StoreTokenPage = (props) => {
    const {token} = useParams();
    const mycookies = new Cookies();
    const navigate = useNavigate();

    const storeToken = useCallback(async() => {        
        try{
            // test token 
            const headers = { 
                'Accept' : 'application/json', 
                'Content-Type' : 'application/json',
                'Authorization' : `Token ${token}`
            }
            const api = axios.create({
                baseURL : config.apiBaseUrl[process.env.NODE_ENV],
                headers: headers,    
                timeout : 50000
            });
            const res = await api.get(`${config.endPoint.getRole}`).then(res => res.data);
            if (res){
                let tmpcookie = {
                    'token' : token, 
                    user_info : res.nama,
                    is_admin: true,
                };
                mycookies.set(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession })
                return navigate('/admin')
            }
            else
            {
                return navigate('/login')
            }
        }catch(error){
            return navigate('/login')
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        storeToken();
    },[storeToken])
    

    return(
        <Fragment>
            <div className='px-[25px] pt-[25px] bg-slate-50/60 pb-[27px]'>
                <div className='bg-slate-50/70 rounded'>
                    Please wait...
                </div>
            </div>
        </Fragment>
    )
}
export default StoreTokenPage;