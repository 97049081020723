import { useCallback, useEffect, useState, useRef } from "react";
import { useGlobalFunction } from "../../constants/globalFunction";
import config from "../../constants/config";
import api from "../../constants/api";
import Moment from "moment";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export function useHomeAdmin(props){
    const isAnswer = props && props.isAnswer ? props.inAnswer : false;
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor
    } = useGlobalFunction();
    const mycookies = new Cookies();
    const navigate = useNavigate();
    const cookiesValue = mycookies.get(config.cookiesName)    
    const [eventTime, setEventTime] = useState(null);
    
    const [view, setView] = useState('vote');
    const [listHistory, setListHistory] = useState([]);
    
    const [isPlay, setIsPlay] = useState(false);
    const [timeLeft, setTimeLeft] = useState("00:00:00");
    const [timeSecond, setTimeSecond] = useState(0);        
    const [info, setInfo] = useState(null);

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = { 
                'is_answer' : isAnswer, 
                'is_running' : true,               
            }
            const res = await api.get(`${config.endPoint.voteAdmin}`, { params : params}).then(res => res.data);            
            if (res ){
                let tmpObj = null;
                let seconds = 0
                let tmpIsPlay = false;
                
                res.results.map(post => {
                    if (post.is_active){
                        tmpObj = post;
                        seconds = post.time_left
                        tmpIsPlay = post.state === 2 ? true : false   
                           
                        if (post.state === 3){
                            let time = Moment(Date.parse(new Date())).add(post.time_left, 's').toDate() - Date.parse(new Date())                        
                            let tmpHours = Math.floor((time / (1000 * 60 * 60)) % 24);
                            let tmpMinues = Math.floor((time / 1000 / 60) % 60);
                            let tmpSeconds = Math.floor((time / 1000) % 60);            
                            
                            if (tmpHours <= 0){
                                tmpHours = '00'
                            }else{
                                tmpHours = leading0(tmpHours)
                            }
                            if (tmpMinues <= 0){
                                tmpMinues = '00'
                            }else {
                                tmpMinues = leading0(tmpMinues)
                            }
                            if (tmpSeconds <= 0){
                                tmpSeconds = '00'
                            }else{
                                tmpSeconds = leading0(tmpSeconds)
                            }                            
                            setTimeLeft(`${tmpHours}:${tmpMinues}:${tmpSeconds}`);

                        }
                    }                    
                    return true;
                })
                setInfo(tmpObj);

                
                if (seconds > 0){                    
                    setEventTime(Moment(Date.parse(new Date())).add(seconds, 's').toDate())                    
                    setTimeSecond(seconds)
                }
                setIsPlay(tmpIsPlay);
                
            }
            // setView('vote')
            setIsLoading(false)      
            // console.log('fetchData >>>>', res);      
        }catch(error){
            setIsLoading(false);            
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));            
            }, config.timeOutValue)
        }
        
    },[setIsLoading, setAlertValue, isAnswer])

    
    const handleStartVote = async(params) => {           
        setIsLoading(true);

        try{
            let tmp = {
                'type' : params
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));


            const res= await api.put(`${config.endPoint.votePauseStart}${info ? info.id : 0}/`, newForm).then(res => res.data);
            
            if (res){
                setTimeLeft(Moment(Date.parse(new Date())).add(res.time_left, 's').toDate())
                // console.log('res is >>>>', res);
            }            
            setIsLoading(false);

        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));            
            }, config.itemPerPage)
        }
    }

    // =================== timer ==================
    const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const leading0 = (num) => {
		return num < 10 ? "0" + num : num;
	};

    // eslint-disable-next-line
	const getTimeUntil = (deadline) => {
		const time = Date.parse(deadline) - Date.parse(new Date());
		if (time < 0) {
			setDays(0);
			setHours(0);
			setMinutes(0);
			setSeconds(0);

            setEventTime(null);
		} else {            
            setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));            
		}
	};

	useEffect(() => {
		if (eventTime && isPlay){
			let timer = setInterval(() => getTimeUntil(eventTime), 1000);
            return () => clearInterval(timer);
			// return () => getTimeUntil(eventTime);
		}
        // eslint-disable-next-line
	}, [eventTime, getTimeUntil, isPlay]);


    useEffect(() => {
        if (!cookiesValue){
            navigate('/')
        }
        if (cookiesValue && !cookiesValue.is_admin){
            navigate('/')
        }
        
        // eslint-disable-next-line 
    },[])

    useEffect(() => {
        fetchData();
    },[fetchData])
    

    const fetchHistory = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = { 
                'is_answer' : isAnswer, 
                'is_running' : false,               
            }
            const res = await api.get(`${config.endPoint.voteAdmin}`, { params : params}).then(res => res.data);
            let tmp = []            
            if (res){
                res.results.map(post => {   
                    let obj = {...post};
                    obj['isOpen'] = true;
                    obj['userAnswer'] = '';
                    obj['vote_state'] = 'stop'  
                    tmp.push(obj);                  
                    return true;
                })
            }            
            setListHistory(tmp)            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));            
            }, config.timeOutValue)
        }
        
    },[setIsLoading, setAlertValue, isAnswer])

    useEffect(() => {
        fetchHistory()
    },[fetchHistory])

   

    const handleView = (params) => {
        setView(params);
    }

    

    const handleStopPause = useCallback(async(tipe='stop', timeSecondValue=0) => {
        console.log('disini >>>>>')
        setIsLoading(true);
        try{
            let tmp = {
                'type' : tipe,
                'time_left' : timeSecondValue
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            
            const res= await api.put(`${config.endPoint.votePauseStart}${info ? info.id : 0}/`, newForm).then(res => res.data);
            
            if (res && res.end_time_from_pause){
                setIsPlay(true);
            }            
            setIsLoading(false);

        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));            
            }, config.itemPerPage)
        }
        // eslint-disable-next-line
    },[]);

    
	
    useEffect(() => {
		if (timeSecond > -2 && isPlay){
			let timer = setInterval(() => {
                setTimeSecond(timeSecond-1)
            }, 1000);
            return () => clearInterval(timer);
			// return () => getTimeUntil(eventTime);
		}
	}, [timeSecond, isPlay]);


    useEffect(() => {
		if (timeSecond <= -2){
			handleStopPause();
		}
        // eslint-disable-next-line
	}, [timeSecond]);


    // ============== web socket =======================
    const ws = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    



    const addMessage = useCallback(async(myMsg) =>  {
        let message = JSON.parse(myMsg);  
        if (message.text.type === 'hasil_vote'){
            if (message.text.play_type === 'start'){
                fetchData();
            }
            if (message.text.play_type === 'pause'){
                fetchData();
            }
            if (message.text.play_type === 'unpause'){
                fetchData();
            }
            if (message.text.play_type === 'results'){
                fetchData();
            }
        }
        if (message.text.type === 'change_view'){
            if (message.text.type === 'vote'){
                fetchData()
            }else{
                fetchHistory();
            }
            setView(message.text.view_type)
        }
        // eslint-disable-next-line
    }, []);
    

    
    
    useEffect(() => {
            console.log('esekusi websocket')
            if (waitingToReconnect) {
                return;
            }

            // Only set up the websocket once
            if (!ws.current) {
                const client = new W3CWebSocket(config.wsBaseUrl[process.env.NODE_ENV] + 'vote_result/');
                ws.current = client;

                window.client = client;

                client.onerror = (e) => console.error('errorrrr >>>>>>', e);

                client.onopen = () => {
                    setIsOpen(true);
                    console.log('ws opened');
                    client.send(JSON.stringify({message : 'ping'}));
                };

                client.onclose = () => {

                    if (ws.current) {
                        // Connection failed
                        console.log('ws closed by server');
                    } else {
                        // Cleanup initiated from app side, can return here, to not attempt a reconnect
                        console.log('ws closed by app component unmount');
                        return;
                    }

                    if (waitingToReconnect) {
                        return;
                    };

                    // Parse event code and log
                    setIsOpen(false);
                    console.log('ws closed');

                    // Setting this will trigger a re-run of the effect,
                    // cleaning up the current websocket, but not setting
                    // up a new one right away
                    setWaitingToReconnect(true);

                    // This will trigger another re-run, and because it is false,
                    // the socket will be set up again
                    setTimeout(() => setWaitingToReconnect(null), 5000);
                };

                client.onmessage = message => {
                    addMessage(message.data);
                };


                return () => {

                    console.log('Cleanup');
                    // Dereference, so it will set up next time
                    ws.current = null;

                    client.close();
                }
            }
        
    }, [waitingToReconnect, addMessage]);
        // eslint-disable-next-line

    
    const handleOpenHistory = (index) => {
        let array = [...listHistory];
        array[index] = {...array[index], isOpen : !array[index].isOpen}
        setListHistory(array);
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor, 
        handleStartVote,
        leading0, days, hours, minutes, seconds, handleStopPause,  timeLeft,
        isOpen,   view, handleView, listHistory, handleOpenHistory, 
        info,
    }
}