import { useCallback, useEffect, useState, useRef } from "react";
import { useGlobalFunction } from "../../constants/globalFunction";
import Cookies from 'universal-cookie';
import config from "../../constants/config";
import api from "../../constants/api";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export function useVoter(){    
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor,
    } = useGlobalFunction();
    const cookiese = new Cookies();
    // const ref = useRef(false);
    const [timeSecond, setTimeSecond] = useState(0);
    const myCookies = cookiese.get(config.cookiesName);
    const [eventTime, setEventTime] = useState(null);
    const navigate = useNavigate();
    const [isPlay, setIsPlay] = useState(false);
    
    const [listData, setListData] = useState([]);
    // eslint-disable-next-line
    const [view, setView] = useState('vote');
    const [listHistory, setListHistory] = useState([]);
    const [listAnswer, setListAnswer] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0)    
    const [detailOpen, setDetailOpen] = useState(false);
    const [modalKonfirmasi, setModalKonfirmasi] = useState({
        show: false,
        list_data : []
    })
    

    const fetchData = useCallback(async() => {
        sessionStorage.removeItem('error_message');
        setIsLoading(true);
        try{
            let params = {
                'unvote_only' : true
            }
            const res = await api.get(`${config.endPoint.vote}`, {params: params}).then(res => res.data);
            let tmp = []
            // let tmpTimeStart = null;
            if (res){
                let seconds = 0

                res.results.map(post => {
                    let obj = {...post};
                    obj['isOpen']= false;
                    tmp.push(obj);
                    if (post.is_active && post.state === 2){
                        // tmpTimeStart = Moment(post.time_end_display, "DD-MM-YYYY HH:mm:ss").toDate();
                        setIsPlay(true);
                    } 
                    if (post.is_active){
                        seconds = post.time_left
                    }
                    setSelectedIndex(post.id)
                    // setTimeSecond(post.time_left)
                    return true;
                })
                // setEventTime(tmpTimeStart)
                setListData(tmp);

                if (seconds > 0){                    
                    setEventTime(Moment(Date.parse(new Date())).add(seconds, 's').toDate())                    
                    setTimeSecond(seconds)
                }
            }
            setIsLoading(false);
        }catch(error){
            const { response } = error;
            if (response && response.status && response.status === 401){
                if (response.data && response.data.message){
                    sessionStorage.setItem('error_message', response.data.message)
                }
                return navigate('/404')
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setIsLoading, setAlertValue])

    const fetchHistory = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                'history_only' : true
            }
            const res = await api.get(`${config.endPoint.vote}`, {params: params}).then(res => res.data);
            let tmp = []
            if (res){
                res.results.map(post => {
                    let obj = {...post};
                    obj['isOpen']= true;
                    tmp.push(obj);
                    return true;
                })
                setListHistory(tmp);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        
            fetchData();
        
        // return () => ref.current = true;
    },[fetchData])

    useEffect(() => {
        fetchHistory();
    },[fetchHistory])

    const handleOpen = (index) => {
        let array = [...listData];
        array[index] = {...array[index], isOpen : !array[index].isOpen}
        setListData(array);
        setDetailOpen(!detailOpen)
    }
    
    const handleOpenHistory = (index) => {
        let array = [...listHistory];
        array[index] = {...array[index], isOpen : !array[index].isOpen}
        setListHistory(array);
    }

    const handleVote = async(post) => {       
        setListAnswer(post.list_answer_group);
        setModalValue(modalValue => ({...modalValue,
            show: true
        }))        
    }

    const handleAnswer = (index,  value) => {
        let array = [...listAnswer];
        if (array[index]['selected'] === value){
            array[index] = {...array[index], selected : ''}
        }else{
            array[index] = {...array[index], selected : value}
        }
        setListAnswer(array);        
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            
            let tmp = [...listAnswer];
            let countUnAnswer = 0;
            tmp.map(post => {
                if (post.selected === ''){
                    countUnAnswer++;
                }
                return true;
            })

            if (countUnAnswer === tmp.length){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Pilih salah satu jawaban', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue);
                return;
            }
            

            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.vote}`, newForm).then(res => res.data);
            if (res){
                handleCloseModal();
                setModalKonfirmasi(modalKonfirmasi => ({...modalKonfirmasi,
                    show: true, 
                    list_data : res.list_results
                }))
            }
            setIsLoading(false);
        }catch(error){
            let { response } = error;
            let msg = 'Unable to vote answer';
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    const handleCancel = () => {
        setListAnswer([]);
        handleCloseModal();
    }

    const handleView = (post) => {
        setView(post);
    }

    const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const leading0 = (num) => {
		return num < 10 ? "0" + num : num;
	};

    // eslint-disable-next-line
	const getTimeUntil = (deadline) => {
		const time = Date.parse(deadline) - Date.parse(new Date());
		if (time < 0) {
			setDays(0);
			setHours(0);
			setMinutes(0);
			setSeconds(0);
		} else {
			setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
			setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
			setMinutes(Math.floor((time / 1000 / 60) % 60));
			setSeconds(Math.floor((time / 1000) % 60));
		}
	};
	
    useEffect(() => {
		if (eventTime && isPlay){
			let timer = setInterval(() => getTimeUntil(eventTime), 1000);
            return () => clearInterval(timer);			
		}
        // eslint-disable-next-line
	}, [eventTime, getTimeUntil, isPlay]);

    useEffect(() => {
		if (timeSecond > -2 && isPlay){
			let timer = setInterval(() => {
                setTimeSecond(timeSecond-1)
            }, 1000);
            return () => clearInterval(timer);
			// return () => getTimeUntil(eventTime);
		}
	}, [timeSecond, isPlay]);


   



    // ============== web socket =======================
    const ws = useRef(null);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);


    // const refetchData = useCallback(async(tmpData) => {
        
        
    //     let tmp = [...tmpData];        
    //     console.log('listData >>>>>>', tmp)
    //     // setEventTime(Moment(message.text.end_time, "DD-MM-YYYY HH:mm:ss").toDate());

    //         // let tmpIndex = tmp.findIndex(x => x.id === selectedIndex)
    //         // if (['start', 'unpause'].includes(message.text.play_type)){
    //         //     setIsPlay(true);
    //         //     if (tmpIndex > -1){
    //         //         tmp[tmpIndex]['state'] = 2 
    //         //     }
    //         // }else if(message.text.play_type === 'pause'){
    //         //     setIsPlay(false);
    //         //     if (tmpIndex > -1){
    //         //         tmp[tmpIndex]['state'] = 3 
    //         //     }
    //         // }else{
    //         //     setIsPlay(false);
    //         //     if (tmpIndex > -1){
    //         //         tmp[tmpIndex]['state'] = 4;
    //         //     }
    //         // }   
    //         // setListData(tmp)
        
    // },[]);

    

    

    const addMessage = useCallback(async(myMsg) =>  {                
        fetchData()
        // eslint-disable-next-line
    },[]);

    
    // console.log('listData is >>>>', listData)
    
    useEffect(() => {            
        if (waitingToReconnect) {
            return;
        }

        // Only set up the websocket once
        if (!ws.current) {
            const client = new W3CWebSocket(config.wsBaseUrl[process.env.NODE_ENV] + 'vote_result/');
            ws.current = client;

            window.client = client;

            // client.onerror = (e) => console.error('errorrrr >>>>>>', e);

            client.onopen = () => {
                setIsOpen(true);
                console.log('ws opened');
                client.send(JSON.stringify({message : 'ping'}));
            };

            client.onclose = () => {

                if (ws.current) {
                    // Connection failed
                    console.log('ws closed by server');
                } else {
                    // Cleanup initiated from app side, can return here, to not attempt a reconnect
                    console.log('ws closed by app component unmount');
                    return;
                }

                if (waitingToReconnect) {
                    return;
                };

                // Parse event code and log
                setIsOpen(false);
                console.log('ws closed');

                // Setting this will trigger a re-run of the effect,
                // cleaning up the current websocket, but not setting
                // up a new one right away
                setWaitingToReconnect(true);

                // This will trigger another re-run, and because it is false,
                // the socket will be set up again
                setTimeout(() => setWaitingToReconnect(null), 700);
            };

            client.onmessage = message => {
                addMessage(message.data);                
            };
            
            return () => {

                console.log('Cleanup');
                // Dereference, so it will set up next time
                ws.current = null;

                client.close();
            }
        }            
        
        // return () => getData();
        // eslint-disable-next-line
    }, [waitingToReconnect]);

    const handleCloseModalKonfirmasi = () => {
        setModalKonfirmasi(modalKonfirmasi => ({...modalKonfirmasi, show: false}));
        fetchHistory();
    }


    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor,myCookies,
        view, listData, listHistory, handleOpen, handleVote, setListHistory, listAnswer,
        handleAnswer, handleCancel, handleSubmit, handleView, handleOpenHistory,
        leading0, days, hours, minutes, seconds, isOpen, setIsPlay, detailOpen, selectedIndex, 
        modalKonfirmasi, handleCloseModalKonfirmasi
    }
}