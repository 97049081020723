import { Fragment } from "react"
import { useVoter } from "./voterFunction";
import LoadingComp from "../../component/LoadingComp";
import AlertComp from "../../component/AlertComp";
import LogoMunas from '../../assets/logomunas.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faClock } from "@fortawesome/free-regular-svg-icons";

const VoterPage = (props) => {
    const {
        myCookies, view, listData, isLoading,
        alertValue, handleCloseAlert,
        handleOpen, modalValue, handleCancel, handleVote,
        listAnswer, handleAnswer, handleSubmit, handleView,
        listHistory, handleOpenHistory,
        leading0,  hours, minutes, seconds, detailOpen, modalKonfirmasi, handleCloseModalKonfirmasi
    } = useVoter();

    
    
    return(
        <Fragment>
            { isLoading && <LoadingComp/>}
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <div className="w-full px-10 py-6">

                <div className='bg-slate-50/60 py-[14px] px-[25px] rounded'>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row">
                            <button className={`${ view === 'vote' ? 'bg-gradient-to-r from-[#1B1464] to-[#67C5D8] text-white' : 'bg-[#D9D9D9] text-black'} text-[20px] font-bold rounded px-7 py-3`} onClick={() => handleView('vote')}>E-Vote</button>
                            <button className={`${ view === 'history' ? 'bg-gradient-to-r from-[#1B1464] to-[#67C5D8] text-white' : 'bg-[#D9D9D9] text-black'} text-[20px] font-bold rounded px-7 py-3 ml-2`} onClick={() => handleView('history')}>History</button>
                        </div>
                        <div className="flex">
                            <div className="text-right">
                                <div className="text-white text-[16px] font-bold bg-blue-500 rounded-full px-8 py-2">{myCookies && myCookies.user_info && myCookies.user_info}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='bg-slate-50/90 py-[14px] px-[25px] rounded-t mt-[20px] shadow-md'>
                        <div>
                            <div className='text-[24px] text-black font-bold'>{view === 'vote' ? 'E-Vote' : 'History'}</div>
                        </div>
                    </div>
                    <div className='bg-slate-50/60 py-[14px] px-[25px] rounded-b'>
                        { view === 'history' &&
                            <>
                                { listHistory && listHistory.length === 0 &&
                                    <div className="flex flex-row justify-center items-center">
                                        <img src={LogoMunas} alt="logo" className='w-2/4'/>
                                    </div>
                                } 
                                { listHistory.map((post, index) => {
                                    return(
                                        <div className='border border-gray-800 rounded bg-slate-50/70' key={index}>
                                            <div className="flex flex-row justify-between items-center py-[15px] px-[25px] ">
                                                <div className='header font-bold text-[18px]'>
                                                    {post.subject}
                                                </div>
                                                <div>
                                                    <button onClick={() => handleOpenHistory(index)}>
                                                        <FontAwesomeIcon icon={faChevronDown}/>
                                                    </button>
                                                </div>
                                            </div>
                                            { post.isOpen &&
                                                <div>
                                                    <div className='py-[80px] flex flex-col justify-center items-center'>
                                                        <div className='font-bold text-[25px] mb-[24px]'>Anda Memilih Jawaban</div>
                                                        { post.the_answer_group.map((answer_group, answer_group_index) => {
                                                            return(
                                                                <div className='grid grid-cols-2 gap-4 w-2/4 bg-white mb-5 px-5 py-3 rounded-md' key={answer_group_index}>                                                                    
                                                                    <div className='text-[16px] font-bold text-gray-500'>
                                                                        <div>{answer_group.perusahaan}</div>
                                                                        <div>Waktu vote : {answer_group.answered_at}</div>
                                                                    </div>
                                                                    <div className='text-[16px] font-bold text-gray-500'>
                                                                        <div>Jawaban : {answer_group.answer}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        }
                        { view === 'vote' &&
                            <>
                                { listData && listData.length === 0 &&
                                    <div className="flex flex-row justify-center items-center">
                                        <img src={LogoMunas} alt="logo" className='w-2/4'/>
                                    </div>
                                }                    
                                { listData.map((post, index) => {
                                    return(
                                        <div className='border border-gray-800 rounded bg-slate-50/70' key={index}>
                                            <div className="flex flex-row justify-between items-center py-[15px] px-[25px] ">
                                                <div className='header font-bold text-[18px]'>
                                                    {post.subject}
                                                </div>
                                                <div>
                                                    <button onClick={() => handleOpen(index)}>
                                                        <FontAwesomeIcon icon={faChevronDown}/>
                                                    </button>
                                                </div>
                                            </div>
                                            { detailOpen &&
                                                <div>
                                                    <div className='py-[80px] flex flex-col justify-center items-center'>
                                                        <button disabled={post.state !== 2} onClick={() => handleVote(post)} className={`${post.state === 2 ? 'bg-blue-400' : 'bg-gray-400'} w-[300px] py-[15px] font-bold text-white rounded`}>Vote</button>
                                                    </div>
                                                    { [1,3].includes(post.state) &&
                                                        <div className='text-center text-[22px] font-bold mb-[10px]'>{ post.state === 1 ? 'Vote belum dimulai' : 'Vote di jeda'}</div>
                                                    }
                                                    { post.state === 2 &&
                                                        <div className="flex flex-row text-[#1B1464] text-[60px] font-bold justify-center">
                                                            <div className='mr-[10px]'><FontAwesomeIcon icon={faClock} /></div>
                                                            <div>{leading0(hours)}</div>
                                                            <div>:</div>
                                                            <div>{leading0(minutes)}</div>
                                                            <div>:</div>
                                                            <div>{leading0(seconds)}</div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
                { modalKonfirmasi.show &&
                    <div className='absolute w-full h-full bg-modal left-0 top-0 flex justify-center'>
                        <div className='relative w-full h-full z-20'>                        
                            <div className='w-1/3 h-fit overflow-auto mt-10 py-10 bg-gradient-to-r from-[#434343] to-[#a3a3a3] rounded-md m-auto z-20 relative'>
                                <div className="body mt-[10px] z-[999]">                                    
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-12 pb-[17px] border-b border-b-white text-center text-[25px] font-bold text-green-600'><FontAwesomeIcon size="3x" icon={faCheckCircle} /></div>
                                        <div className='col-span-12 pb-[17px] border-b border-b-white text-center text-[25px] font-bold text-white'>Berhasil Melakukan Vote</div>
                                        <div className='col-span-12 w-full py-[35px] px-10'>
                                            { modalKonfirmasi.list_data.map((post, index) => {
                                                return(
                                                    <div key={index} className='grid grid-cols-3 gap-4 justify-between mb-3'>
                                                        <div className='col-span-2 mt-[5px] text-[15px] text-white'>{post.perusahaan}</div>
                                                        <div className='col-span-1 mt-[5px] text-[15px] text-white'>{post.answer}</div>
                                                    </div>
                                                )                                        
                                            })}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='footer  border-t border-t-white pt-5 px-10'>
                                    <div className='flex flex-row justify-center'>
                                        <button className='px-[20px] py-[10px] text-white text-[15px] font-bold bg-[#1B1464] rounded' onClick={handleCloseModalKonfirmasi}>Lanjutkan</button>                                        
                                    </div>
                                </div>
                            </div>                        
                            <div className='absolute w-full h-full bg-modal top-0 left-0 z-10' onClick={handleCloseModalKonfirmasi}></div>
                        </div>
                    </div>
                }
                { modalValue.show &&
                    <div className='absolute w-full h-full bg-modal left-0 top-0 flex justify-center'>
                        <div className='relative w-full h-full z-20'>                        
                            <div className='w-3/4 h-fit overflow-auto mt-10 py-10 bg-gradient-to-r from-[#434343] to-[#a3a3a3] rounded-md m-auto z-20 relative'>
                                <div className="body mt-[10px] z-[999]">                                    
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-12 pb-[17px] border-b border-b-white text-center text-[25px] font-bold text-white'>Vote Untuk</div>
                                        <div className='col-span-12 w-full py-[35px] px-10'>
                                            { listAnswer.map((post, index) => {
                                                return(
                                                    <div key={index} className='grid grid-cols-4 gap-4 justify-between mb-3'>
                                                        <div className='col-span-1 mt-[5px] text-[15px] text-white'>{post.perusahaan_name}</div>
                                                        { post.list_answer.map((answer, indexAnswer) => {
                                                            return(
                                                                <button 
                                                                    key={indexAnswer}                                                            
                                                                    className={`font-bold text-[15px]
                                                                        py-[10px] px-2 rounded   ${answer.id === post.selected ? 'bg-blue-500 text-white' : 'bg-[#D9D9D9] text-[#585858]'}
                                                                    `}
                                                                    onClick={() => handleAnswer(index,  answer.id)}
                                                                >{answer.answer}
                                                                </button>
                                                            )
                                                        })} 
                                                    </div>
                                                )                                        
                                            })}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='footer  border-t border-t-white pt-5 px-10'>
                                    <div className='flex flex-row justify-end'>
                                        <button className='px-[20px] py-[10px] text-white text-[15px] font-bold bg-[#610C0C] rounded' onClick={handleSubmit}>Submit</button>
                                        <button className='px-[20px] py-[10px] text-[#585858] text-[15px] font-bold bg-[#D9D9D9] ml-2 rounded' onClick={handleCancel}>Batal</button>
                                    </div>
                                </div>
                            </div>                        
                            <div className='absolute w-full h-full bg-modal top-0 left-0 z-10' onClick={handleCancel}></div>
                        </div>
                    </div>
                }
            </div>

        </Fragment>
    )
}
export default VoterPage;