import {  useState } from "react";
import Cookies from 'universal-cookie';
import config from "./config";


export function  useGlobalFunction(){
    const [isLoading, setIsLoading] = useState(false);
    
    // eslint-disable-next-line
    const cookies = new Cookies();
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : 'ba',
        color : 'danger'
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null,
        url : null
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],        
        lastPage : 1,
	});
    

    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
        console.log('close modal >>>')
    }

    const catchFetching = (error) => {
        let status = error && error.response && error.response.status ? error.response.status : '';
        if (status && [401, 403].includes(status)) {            
            cookies.remove(config.cookiesName, { path: '/' });	
            return window.location.href = "/login"
        }
    }    


    const getStatusColors = (params) => {
        let tmp;
        
        if (params === 3){
            tmp =  {
                backgroundColor:  'rgb(0, 170, 255)',
                color : '#fff'
            }
        }else if(params === 2){
            tmp =  {
                backgroundColor:  'rgb(255, 205, 205)',
                color : 'rgb(255, 15, 15)'
            }
        }else if(params === 1){
            tmp =  {
                backgroundColor:  'rgb(224, 248, 229)',
                color : 'rgb(1, 184, 26)'
            }
        }else{
            tmp =  {
                backgroundColor:  '#5c5c5c',
                color : '#fff'
            }
        }
        tmp['fontSize'] = '10px'
        return tmp
    }

    const getColor = (params) => {
        if (params === 3){
            return {
                backgroundColor:  '#D9D9D9',
                color : '#A9A9A9'
            }
        }else if(params === 2){
            return {
                backgroundColor:  'rgb(255, 205, 205)',
                color : 'rgb(255, 15, 15)'
            }
        }else if(params === 1){
            return {
                backgroundColor:  'rgb(224, 248, 229)',
                color : 'rgb(1, 184, 26)'
            }
        }else{
            return {
                backgroundColor:  '#5c5c5c',
                color : '#fff'
            }
        }
    }


    

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor,
    }
}