import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Logo from '../assets/logoapjii.png'

const DefaultLayoutVoter = (props) => {
    return(
        <Fragment>
            <div className="bg-image-utama bg-cover bg-center h-screen overflow-hidden overflow-y-auto">
                <div className='flex flex-row justify-center mt-10'>
                    <img src={Logo} alt="logo" />
                </div>
                {/* <div className='header bg-slate-50/40 w-full h-[80px]'>

                </div> */}
                <div className="w-full">
                    <Outlet/>
                </div>
			</div>
        </Fragment>
    )
}
export default DefaultLayoutVoter;