import { Fragment } from "react";
import LogoApjii from "../../assets/logoapjii.png";


const LoginPage = (props) => {
	return (
		<Fragment>
			<div className="bg-image-utama bg-cover bg-center h-screen">
				<div className="flex h-screen absolute  m-auto left-0 right-0 justify-center items-center ">
					<div className="flex w-96 px-4 py-6 bg-slate-50/40 rounded-xl">
						<div className="w-full flex flex-col">
							<div className="w-full flex justify-center items-center pb-8">
								<img src={LogoApjii} className="w-40" alt="logo" />
							</div>
							<div>
								<h1 className="w-full text-white font-semibold text-center pb-2 text-lg">
									Hi, Wellcome
								</h1>
								<h1 className="w-full text-white font-bold text-center text-lg">
									E-VOTE MUNASLUB AD / ART APJII 2023
								</h1>
							</div>
							<div className="flex flex-col justify-center items-center pt-8">
								<input
									type="text"
									className="w-full rounded-md text-lg text-center px-2 py-2 font-semibold"
									placeholder="INPUT PIN AKSES"
								/>
								<button
									className="w-full bg-blue-800 text-white font-bold py-2 rounded-lg mt-2"

								>
									Login
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default LoginPage;
