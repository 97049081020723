import { Fragment, useCallback, useEffect, useState, useRef } from "react"
import { useLocation, 
    // useNavigate 
} from "react-router-dom"
import config from "../../constants/config";
import { useCookies } from 'react-cookie';
import axios from 'axios'
import Cookies from 'universal-cookie';
import AlertComp from "../../component/AlertComp";

const VerifikasiEmailPage = (props) => {
    const location = useLocation();
    const ref = useRef(false);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : 'ba',
        color : 'danger'
    });
    const [isLoading, setIsLoading] = useState(true);
    const cookiese = new Cookies();
    // eslint-disable-next-line 
    const [cookies, setCookie] = useCookies([config.cookiesName]);
    // const navigate = useNavigate();

    const getExpiredTime = useCallback(async() => { 
        sessionStorage.removeItem('error_message');
        cookiese.remove(config.cookiesName, { path: '/' });	                      
        try{
            let params = {
                encrypt :location.search.replace('?q=', '')
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(params))            
            const res = await axios.post(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.voterLogin}`, newForm).then(res => res.data);
            if (res){                
                let tmpcookie = {
                    'token' : res.token, 
                    'user_info' : res.user_info.nama,
                    'perusahaan' : res.user_info.perusahaan,
                    'is_admin' : false
                };                
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });                
                return window.location.href = '/'
            }
            
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            if (response && response.status && response.status === 401){
                if (response.data && response.data.message){
                    sessionStorage.setItem('error_message', response.data.message)
                }
                // return navigate('/404')
            }     
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setCookie, location.search])

    useEffect(() => {
        if (!ref.current) {
            getExpiredTime();
        }
        return () => ref.current = true;
    },[getExpiredTime])

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false}))
    }

    return(
        <Fragment>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <div className="px-[68px] py-[26px] bg-slate-50/60 rounded-xl mb-[20px]">
                <div className='px-[25px] pt-[25px] bg-slate-50/60 pb-[27px] rounded'>
                    <div className='flex flex-row w-full justify-center items-center text-[#1B1464] text-[60px] font-bold pr-4'>
                        { isLoading &&
                            <div>Waiting ..... </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default VerifikasiEmailPage