import { useRoutes } from 'react-router-dom'
import LoginPage from '../views/Auth/LoginPage';
// import HomePage from '../views/HomePage/HomePage';
import DefaultLayout from '../component/DefaultLayout';
import HomeAdminPage from '../views/HomeAdmin/HomeAdminPage';
import StoreTokenPage from '../views/StoreToken/StoreTokenPage';
import VerifikasiEmailPage from '../views/VerifikasiEmail/VerifikasiEmailPage';
import DefaultLayoutVoter from '../component/DefaultLayoutVoter';
import VoterPage from '../views/VoterPage/VoterPage';
import NotFoundPage from '../views/404/NotFoundPage';



let voterChild = [
    {
        path: '/verifikasi-email/',
        element: <VerifikasiEmailPage />,
        exact : true,
    },
    {
        path: '',
        element: <VoterPage />,
        exact : true,
    },
    {
        path: '/404',
        element: <NotFoundPage />,
        exact : true,
    },
]

const voterRoutes = {
    path : '',
    element : <DefaultLayoutVoter/>,
    children :  voterChild,
    exact : true,
}



const authRoutes = {
    path : '/login',
    element : <LoginPage/>,   
    exact: true, 
}


let homeChild = [
    {
        path: '/admin',
        element: <HomeAdminPage />,
        exact : true,
    },
    {
        path: '/admin/store-token/:token',
        element: <StoreTokenPage />,
        exact : true,
    },    
]

const homeRoutes = {
    path : '/admin',
    element : <DefaultLayout/>,
    children :  homeChild,
    exact : true,
}
    
export default function ThemeRoutes() {    
    return useRoutes([
        authRoutes, homeRoutes, voterRoutes
    ]);
}