import {  faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react"
import { useHomeAdmin } from "./homeAdminFunction";
import LoadingComp from "../../component/LoadingComp";
import AlertComp from "../../component/AlertComp";
import { faChevronDown, faChevronUp, faCircle } from "@fortawesome/free-solid-svg-icons";

const HomeAdminPage = (props) => {
    const { isLoading,  alertValue, handleCloseAlert,
        handleStartVote,
        leading0,  minutes, seconds, hours,  timeLeft,  
        isOpen, view, handleView, listHistory, handleOpenHistory,
        info
    } = useHomeAdmin();
    return(
        <Fragment>
            { isLoading && <LoadingComp/>}
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <div className="bg-slate-50/60 py-[20px] px-10">
                <div className="flex flex-row justify-between items-center">
                    <div><FontAwesomeIcon icon={faCircle} className={isOpen ? 'text-green-600' : 'text-gray-400'}/> <span className='font-bold text-[20px]'>{ isOpen ? 'Online' : 'Offline'}</span></div>
                    {/* <div className="flex flex-row justify-end items-center">
                        <button className={`${ view === 'vote' ? 'bg-gradient-to-r from-[#1B1464] to-[#67C5D8] text-white' : 'bg-[#D9D9D9] text-black'} text-[20px] font-bold rounded px-7 py-3 ml-2`} onClick={() => handleView('vote')}>E-Vote</button>
                        <button className={`${ view === 'history' ? 'bg-gradient-to-r from-[#1B1464] to-[#67C5D8] text-white' : 'bg-[#D9D9D9] text-black'} text-[20px] font-bold rounded px-7 py-3 ml-2`} onClick={() => handleView('history')}>History</button>
                    </div> */}
                    <div className="flex flex-row justify-end items-center">
                        <div className='text-[22px] font-bold'>{view.toUpperCase()}</div>
                    </div>
                </div>
            </div>
            { view === 'history' &&
                <div className='px-10 py-6'>
                    { listHistory.map((post, index) => {
                        return(
                            <div className="px-[68px] py-[26px] bg-slate-50/60 rounded-xl mb-[20px]" key={index}>
                                <div className='px-[25px] pt-[25px] bg-slate-50/60 pb-[27px] rounded'>
                                    <div className='bg-slate-50/70 rounded'>
                                        <div className="flex flex-row justify-between items-center py-[15px] px-[25px] ">
                                            <div className='header font-bold text-[18px]'>
                                                {post.subject}
                                            </div>
                                            <div>
                                                <button onClick={() => handleOpenHistory(index)}>
                                                    <FontAwesomeIcon icon={post.isOpen ? faChevronUp : faChevronDown}/>
                                                </button>
                                            </div>
                                        </div>
                                        { post.isOpen &&
                                            <div className='flex flex-row border-t border-t-gray-400'>
                                                { post.answer_count.map((answer, answerIndex) => {
                                                    return(
                                                        <div className='flex-1 flex flex-col items-center border-r border-r-gray-400' key={answerIndex}>
                                                            <div className='text-[150px] text-[#1B1464] mt-[30px] font-bold' style={{lineHeight: 1}}>{answer.result}</div>
                                                            <div className='text-[25px] text-black font-bold mb-[20px]'>{answer.answer}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                    { post.isOpen &&
                                        <div className='px-[25px] pt-[22px] bg-slate-50/60 mt-[18px] pb-[18px] rounded'>
                                            <div className='text-[40px] font-bold text-center'>Results Jawaban Vote</div>
                                            <div>
                                                <table className='w-full table-results-vote'>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Nama Perusahaan</th>
                                                            <th>Nama User</th>
                                                            <th>Jawaban</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { post.answer_results.map((answer, answerIndex) => {
                                                            return(
                                                                <tr key={answerIndex}>
                                                                    <td>{post.answer_results.length - answerIndex}</td>
                                                                    <td>{answer.perusahaan}</td>
                                                                    <td>{answer.nama}</td>
                                                                    <td>{answer.answer}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            { view === 'vote' && info &&
                <div className='px-10 py-6'>
                    { info && [1].includes(info.state) &&
                        <div className="px-[68px] py-[26px] bg-slate-50/60 rounded-xl mb-[20px]">
                            <div className='px-[25px] pt-[25px] bg-slate-50/60 pb-[27px]'>
                                <div className='bg-slate-50/70 rounded'>
                                    <div className="header-question pt-[22px] pb-[12px] text-center font-bold text-[25px] border-b border-b-gray-400">{info.subject}</div>
                                    <div className='text-center pt-[40px] pb-[21px]'>
                                        <button 
                                            onClick={() => handleStartVote('start')} 
                                            className='bg-slate-400/40 py-[15px] px-[208px] rounded font-bold text-[25px] shadow-lg'
                                        >Start Vote</button>
                                        <div className='flex flex-row mt-[22px] justify-center items-center text-[#1B1464] text-[60px] font-bold'>
                                            <div className='mr-[10px]'><FontAwesomeIcon icon={faClock} /></div>
                                            <div>{info.minutes} Menit</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row mt-[44px] text-[25px] font-bold text-[#000] justify-between'>
                                    <div>Jumlah Peserta : {info.jumlah_peserta_absen}</div>
                                    <div>Peserta Sudah Vote :</div>
                                    <div>Peserta Belum Vote :</div>
                                </div>
                            </div>
                        </div>
                    }
                    { info && [2,3].includes(info.state) &&
                        <div className="px-[68px] py-[26px] bg-slate-50/60 rounded-xl mb-[20px]">
                            <div className='px-[25px] pt-[25px] bg-slate-50/60 pb-[27px] rounded'>
                                <div className={`grid grid-cols-12 justify-center items-center ${info.state === 3 ? 'text-gray-400' :  'text-[#1B1464]'} text-[60px] font-bold pr-4`} style={{lineHeight: 1}}>
                                    <div className='col-span-10   grid grid-cols-4'>
                                        <div className="col-span-1 col-start-1">
                                            { info.state === 2 ?
                                                <div className="flex flex-row">
                                                    <div className='mr-[10px]'><FontAwesomeIcon icon={faClock} /></div>
                                                    <div>{leading0(hours)}</div>
                                                    <div>:</div>
                                                    <div>{leading0(minutes)}</div>
                                                    <div>:</div>
                                                    <div>{leading0(seconds)}</div>
                                                </div>
                                                :
                                                <div>{timeLeft}</div>
                                            }
                                            { info.state === 3 &&
                                                <div className='text-[24px] text-green-800 mb-2 ml-2 mt-2' style={{lineHeight: 1}}>Paused</div>
                                            }                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='bg-slate-50/70 rounded'>
                                    <div className="header-question pt-[22px] pb-[12px] text-center font-bold text-[25px] border-b border-b-gray-400">{info.subject}</div>
                                    <div className='flex flex-row'>
                                        { info.answer_count.map((answer, answerIndex) => {
                                            return(
                                                <div className='flex-1 flex flex-col items-center border-r border-r-gray-400' key={answerIndex}>
                                                    <div className='text-[150px] text-[#1B1464] mt-[30px] font-bold' style={{lineHeight: 1}}>{answer.result}</div>
                                                    <div className='text-[25px] text-black font-bold mb-[20px]'>{answer.answer}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-row mt-[44px] text-[25px] font-bold text-[#000] justify-between'>
                                    <div>Jumlah Peserta : {info.jumlah_peserta_absen}</div>
                                    <div>Peserta Sudah Vote : {info.participant.voter.length}</div>
                                    <div>Peserta Belum Vote : {parseInt(info.jumlah_peserta_absen) - parseInt(info.jumlah_peserta_absen)}</div>
                                </div>
                            </div>

                            <div className='px-[25px] pt-[22px] bg-slate-50/60 mt-[18px] pb-[18px] rounded'>
                                <div className='text-[40px] font-bold text-center'>Results Jawaban Vote</div>
                                <div>
                                    <table className='w-full table-results-vote'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nama Perusahaan</th>
                                                <th>Nama User</th>
                                                <th>Jawaban</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { info.answer_results.map((answer, answerIndex) => {
                                                return(
                                                    <tr key={answerIndex}>
                                                        <td>{info.answer_results.length - answerIndex}</td>
                                                        <td>{answer.perusahaan}</td>
                                                        <td>{answer.nama}</td>
                                                        <td>{answer.answer}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }                    
                </div>
            }
        </Fragment>
    )
}
export default HomeAdminPage;